/*
  App
*/

html {
    overflow: hidden;
    scrollbar-width: thin;
    scrollbar-color: var(--gray-11) transparent;
}

::-webkit-scrollbar {
    overflow: visible;
    width: 4px;
}

::-webkit-scrollbar-track {
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb {
    background-color: var(--gray-11);
    height: 30px;
}

/*
  Radix Themes
*/

// .radix-themes {
//     --default-font-family: 'Inter Regular';
//     --heading-font-family: 'Inter Bold';
// }

*:focus {
    outline: none !important;
}

/*
  Pulsating Icon
*/

.pulsating-icon {
    display: inline-block;
    animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        transform: scale(0.2);
    }

    50% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(0.2);
    }
}

/*
    Textarea
*/

textarea {
    resize:none!important;
    outline: none!important;
    -webkit-box-shadow: none!important;
    -moz-box-shadow: none!important;
    box-shadow: none!important;
}

/*
  Tags input
*/

.tags-input-container {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
}
  
.tags-input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: none;
    padding: 0px 5px;
    background-color: transparent;
}

.tag-item {
    background-color: transparent;
    font-size: 0.78rem;
    line-height: 1rem;
    color: var(--accent-9);
    padding: 0px 5px 0px 0px;
    margin-right: 8px;
    border-radius: 0px;
    display: flex;
    align-items: center;
}

.tag-close {
    margin-left: 3px;
    cursor: pointer;
    color: var(--accent-9);
}

.tag-input {
    font-size: 0.8rem;
    border: none;
    outline: none;
    flex-grow: 1;
    min-width: 40px;
    // padding: 5px;
    background-color: transparent;
}

/*
  Toaster
*/

.toast {
    font-family: 'Inter Regular', sans-serif;
    font-size: 0.85em;
}

/*
  Tiptap
*/

.tiptap {
    outline: none;
    p {
        font-size: 0.9rem;
        margin-bottom: auto;
    }
    li::marker {
        font-size: 0.85rem;
    }
    pre {
        background-color: var(--accent-2);
        padding: 10px;
        line-height: 1.2rem;
        color: inherit;
        font-size: 0.8rem;
    }
    h1 {
        font-size: 1.2rem;
        font-family: 'Inter Bold';
        letter-spacing: -0.5px;
    }
    h2 {
        font-size: 1rem;
        font-family: 'Inter Bold';
        letter-spacing: -0.5px;
    }
    h3 {
        font-size: 0.85rem;
        font-family: 'Inter Bold';
        letter-spacing: -0.5px;
    }
    h4 {
        font-size: 0.75rem;
        font-family: 'Inter Bold';
        letter-spacing: -0.5px;
    }
    .mention {
        background-color: var(--accent-light);
        border-radius: 0.4rem;
        box-decoration-break: clone;
        color: var(--accent);
        padding: 0.1rem 0.3rem;
    }
    a {
        color: var(--blue-9);
    }
    blockquote {
        border-left: 2px solid var(--gray-9);
        padding-left: 10px;
        font-style: italic;
    }

    ul,
    ol {
      padding: 0 1rem;
      margin: 0.5rem 1rem 0.5rem 0.4rem;
    }
    
    ul[data-type="taskList"] {
        list-style: none;
        margin-left: 10px;
        padding: 0;
    
        li {
          align-items: flex-start;
          display: flex;
    
          > label {
            flex: 0 0 auto;
            margin-right: 0.5rem;
            user-select: none;
          }
    
          > div {
            flex: 1 1 auto;
          }
        }
    
        input[type="checkbox"] {
          cursor: pointer;
          accent-color: var(--accent-9);
        }
    
        ul[data-type="taskList"] {
          margin: 0;
          margin-top: 0.5rem;
        }
    }

}

.tiptap-pre {
    // background-color: var(--bg-primary-dark);
    // border-radius: 0.5rem;
    // color: var(--text-primary-dark);
    font-family: monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
        background: none;
        color: inherit;
        font-size: 0.8rem;
        padding: 0;
    }

    /* Code styling */
    .hljs-comment,
    .hljs-quote {
        color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
        color: #f98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
        color: #fbbc88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
        color: #b9f18d;
    }

    .hljs-title,
    .hljs-section {
        color: #faf594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
        color: #70cff8;
    }

    .hljs-emphasis {
        font-style: italic;
    }

    .hljs-strong {
        font-weight: 700;
    }
}

.tiptap-image {
    width: 100%;
}

.tiptap-link {
    cursor: pointer;
}

.tiptap-table {
    display: block;
    table-layout: fixed;
    width: 100%;
    max-width: 100%;
    border-color: var(--separator);
}

.tiptap-blockquote {
    border-left: 2px solid var(--separator);
    padding-left: 10px;
    font-style: italic;
}

.tiptap-table-header {
    font-weight: bold;
    background-color: var(--bg-secondary);
}

.bubble-menu {
    background-color: var(--gray-1);
    border: 1px solid var(--gray-4);
    border-radius: 0.1rem;
    box-shadow: var(--gray-2);
    display: flex;
    padding: 0.2rem;
    border: none;
    outline: none;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100vw;
  }

.btn-tiptap-bubble-menu {
    border-radius: 1px;
    margin: 2px;
    background-color: var(--gray-1);
    border: none;
    outline: none;
    font-size: 0.8rem;
}

.btn-tiptap-bubble-menu-active {
    border-radius: 1px;
    margin: 2px;
    background-color: var(--accent-6);
    border: none;
    outline: none;
}

.btn-tiptap-bubble-menu-hidden {
    display: none;
}

.tippy-box {
    display: flex;
    padding: 3px;
    border-radius: 0.2rem;
    background-color: var(--bg-secondary);
    box-shadow: var(--separator);
}

/* Date picker */

.date-picker-input {
    background-color: var(--accent-2);
    outline: none;
    border: none;
}